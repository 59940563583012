@mixin breakpoint($class) {
    @if $class==xs {
      @media (max-width: 575px) {
        @content;
      }
    }
    @else if $class==sm {
        @media (min-width: 576px) {
          @content;
        }
    }
    @else if $class==md {
      @media (min-width: 768px) {
        @content;
      }
    }
    @else if $class==lg {
      @media (min-width: 992px) {
        @content;
      }
    }
    @else if $class==xl {
        @media (min-width: 1200px) {
          @content;
        }
      }
    @else if $class==xxl {
        @media (min-width: 1400px) {
          @content;
        }
      }
    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg, xl, xxl";
    }
  }
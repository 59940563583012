.breadcrumb { 
    --bs-breadcrumb-font-size: var( --ic-font-size-sm );
    --bs-breadcrumb-divider: "\e918";
}
.breadcrumb-item { 
    align-items: center;
    display: flex;

    &:before {
        font-family: "ic-icons" !important;
        font-size: calc( var(--ic-font-size-xs) * .7);
    }
}
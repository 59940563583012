.sidebar-main {
    background-color: var(--ic-color-primary);
    color: #fff;
    width: 265px;
    height: 100%;
    padding: 0 0.3rem;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1093;
    transition: width 0.1s ease-in-out;
}

.custom-container .sidebar .dropend .dropdown-toggle::after {
    display: none;
}

.custom-container .sidebar a {
    color: #fff;
    text-decoration: none;
}

.custom-container .sidebar .sidebartop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 1rem;

    .menu-toggle {
        color: #fff;
        font-size: 1.25rem;
        margin: 0px;

        &:hover {
            background-color: #fff;
            color: var(--ic-color-primary);
        }
    }
}
.short .custom-container .sidebar .sidebartop {
    .menu-toggle {
        margin: 0px auto;
    }
}

.custom-container .sidebar .sidebartop .logo {
    width: 70%;
}

.custom-container .sidebar .sidebartop .logo img {
    height: auto;
    width: 100%;
}

.custom-container .sidebar .sidebartop .logo-mobile {
    display: none;
}

.custom-container .sidebar nav {
    height: calc( 100% - 61px );
    overflow-y: auto;
    scrollbar-color: var(--ic-color-primary-low ) rgba( var(--ic-color-primary-strong-rgb), .8);
    scrollbar-width: thin;
}
.custom-container .sidebar nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.custom-container .sidebar nav ul li {
    display: block;
    align-items: center;
    position: relative;
    background-color: transparent;
}

.custom-container .sidebar nav ul li a {
    display: flex;
    align-items: center;
    justify-content: start;
    border-radius: 12px;
    padding: 0.5rem 0.6rem;
    height: 50px;
    margin-bottom: 0.5rem;
    transition: background-color 0.15s ease-in-out;
    font-size: 16px;
}

.custom-container .sidebar nav ul li a i {
    font-size: 2rem;
    transition: color 0.1s ease-in-out;
    background-color: transparent !important;
    margin-right: 1rem;
}

.custom-container .sidebar nav ul li a:hover, .custom-container .sidebar nav ul li a:focus {
    background-color: #fff;
    color: var(--ic-color-primary);
}

.custom-container .sidebar nav ul li a:hover i, .custom-container .sidebar nav ul li a:focus i {
    color: var(--ic-color-primary);
}

.custom-container .sidebar nav ul li .dropdown-toggle.show {
    background-color: #fff;
    color: var(--ic-color-primary);
}

.custom-container .sidebar nav ul li .dropdown-toggle.show i {
    color: var(--ic-color-primary);
}

.custom-container .sidebar nav ul li .dropdown-menu {
    border: 0;
    border-radius: 12px;
    padding: 1rem 0;
    margin-left: -7px !important;
}

.custom-container .sidebar nav ul li .dropdown-menu li {
    margin: 0;
}

.custom-container .sidebar nav ul li .dropdown-menu li a {
    width: auto;
    color: var(--ic-color-primary);
    justify-content: start;
    height: 40px;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
    margin: 0;
    padding: 0.4rem 1.3rem;
}

.custom-container .sidebar nav ul li .dropdown-menu li a:hover, .custom-container .sidebar nav ul li .dropdown-menu li a:focus {
    background-color: var(--ic-color-primary);
    color: #fff;
}

.custom-container .main,
.custom-container .footer {
    margin-left: 265px;
    transition: margin-left 0.1s ease-in-out;
}

.short .sidebar {
    width: 70px;
    text-align: center;
    transition: width 0.2s ease-in-out;
}

.short .sidebar .logo,
.short .sidebar .searchInput,
.short .sidebar .avatar,
.short .sidebar .name {
    display: none;
}
.short .sidebar .text {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
    transition: width .2s ease-in-out;
}
.short .sidebar .sidebartop {
    display: block;
}

.short .sidebar .sidebartop .logo-mobile {
    display: none;
}

.short .sidebar .text.hover {
    display: block !important;
    background-color: rgba(255, 255, 255, 0.9);
    color: var(--ic-color-primary);
    padding: 0.5rem;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 4.2rem;
    border-radius: 0.25rem;
}

.short .sidebar nav ul li a {
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.short .sidebar nav ul li a i {
    margin-right: 0;
}

@media (min-width: 992px) {
    .sidebar-main-mobile {
        display: none;
    }
}

@media (max-width: 991px) {
    .sidebar-main-mobile {
        display: flex;
    }
    .sidebar-main-mobile nav {
        background-color: transparent;
        border: 0;

        .btn-menu-mobile-item {
            border-radius: 12px;
            background-color: transparent;
            color: #fff;
            font-size: 1rem;
            font-weight: bold;
            padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);

            i {
                font-size: 1.5rem;
                margin-right: 1rem;
            }
        }
        .accordion-item {
            background-color: transparent;
            border: 0;

            .accordion-button.collapsed {
                border-radius: 12px;
                background-color: transparent;
            }

            .accordion-button:not(.collapsed) {
                background-color: #fff;
                color: var(--ic-color-primary);
            }

            .accordion-button {
                color: #fff;
                border-radius: 12px 12px 0 0;

                i {
                    font-size: 1.5rem;
                    margin-right: 1rem;
                }
            }

            .accordion-body {
                background-color: #fff;
                border-radius: 0 0 12px 12px;
                padding: 12px 5px;

                a {
                    color: var(--ic-color-primary);
                    padding: 0.6rem 1rem;

                    &:hover {
                        background-color: var(--ic-color-primary);
                        color: #fff;
                    }
                }
            }
        }
    }
}
button {
    transition: background-color 0.1s ease-in-out;
    transition: color 0.1s ease-in-out;
}
summary[role=button] {
   color: var(--bs-btn-color);
}
details summary[role=button].btn-link {
    display: flex;
    align-items: center;
    gap: calc(var(--ic-spacing, 1rem)* .5);
}
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--ic-color-primary);
    --bs-btn-border-color: var(--ic-color-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #02A5D5;
    --bs-btn-hover-border-color: #02A5D5;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #02A5D5;
    --bs-btn-active-border-color: #02A5D5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #333;
    --bs-btn-disabled-bg: #808080;
    --bs-btn-disabled-border-color: #808080;
}

.btn-outline-primary {
    --bs-btn-color: var(--ic-color-primary);
    --bs-btn-border-color: var(--ic-color-primary);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--ic-color-primary);
    --bs-btn-hover-border-color: #0d6efd;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--ic-color-primary);
    --bs-btn-active-border-color: var(--ic-color-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #333;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #808080;
    --bs-gradient: none;
}
.btn-outline-secondary {
    --bs-btn-color: var( --ic-color-primary );
    --bs-btn-border-color: var( --bs-border-color );
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var( --ic-color-primary );
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var( --ic-color-primary );
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check+.btn.btn-outline-secondary:hover, .btn-check+.btn.btn-outline-secondary:focus {
    background-color: var( --ic-color-primary );
    color: #fff;
}
.btn-link {
    --bs-btn-color: var( --ic-color-link );
    --bs-btn-hover-color: var( --ic-color-link-hover );;

    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }
}
.btn-second {
    border-radius: 12px;
    border: 0;
    color: var( --ic-color-primary );
    background-color: #fff;
    transition: color 0.1s ease-in-out;
    transition: background-color 0.1s ease-in-out;
}

.btn-second:hover {
    color: #fff;
    background-color: var( --ic-color-primary );
}

.btn-ghost {
    --bs-btn-color: var( --ic-color-primary );
    --bs-btn-hover-bg: var( --ic-color-primary );
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: #fff;

    border: 0;
    transition: color 0.1s ease-in-out;
}
.btn-circle {
    --bs-btn-border-radius: 50%;
    --bs-btn-line-height: 1em;
    --bs-btn-padding-x: 0.375rem;
    &.btn-lg{
        --bs-btn-padding-y: .75rem;
        --bs-btn-padding-x: .75rem;
    }
}
.btn-colors-inverted {
    color: #fff;
}
.btn-colors-inverted:hover {
    background-color: #fff;
    color: var(--ic-color-primary);
}
.btn-to-top {
    z-index: 1000;
    bottom: 10vh;
    position: fixed;
    right: var(--ic-spacing);
}

.slides-manager {
    .list-group {
        padding-left: 1rem;
    }
    .slide-item {
        display: flex;
        flex-direction: row;
        padding: 0;
    }
    .btn-edit {
        border-radius: 0px;
        flex-grow: 1;
        text-align: left;
    }
    .slide-detail {
        background-color: #fff;
        position: fixed;
        top: 0;
        bottom: 0;
        right:0;
        z-index: 1020;

        @include breakpoint( lg ) {
            position: relative;
            top: auto;
            bottom: auto;
            right: auto;  
        }
    }
}
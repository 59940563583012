:root {
    --ic-color-primary: #d43036;
    /* Red-colors */
    --ic-color-red-10: #0a0101;
    --ic-color-red-20: #2a0909;
    --ic-color-red-30: #541213;
    --ic-color-red-30-rgb: 84,18,19;
    --ic-color-red-40: #85161b;
    --ic-color-red-50: #b51e27;
    --ic-color-red-60: #d43036;
    --ic-color-red-60-rgb: 212, 48, 54;
    --ic-color-red-70: #fc6662;
    --ic-color-red-80: #ff9890;
    --ic-color-red-90: #ffccc7;
    --ic-color-red-95: #ffe5e2;
    /* Green-colors */
    --ic-color-green-50: #007B00;
    /* Gray-colors */
    --ic-gray-10: #030303;
    --ic-gray-20: #161616;
    --ic-gray-30: #2e2e2e;
    --ic-gray-40: #484848;
    --ic-gray-50: #636363;
    --ic-gray-60: #808080;
    --ic-gray-70: #9e9e9e;
    --ic-gray-80: #bebebe;
    --ic-gray-90: #dedede;
    --ic-gray-95: #eeeeee;
    --ic-white: #ffffff;
    
    // spacing
    --ic-size-space-0: 0rem;
    --ic-size-space-025: 0.125rem;
    --ic-size-space-050: 0.25rem;
    --ic-size-space-075: 0.375rem;
    --ic-size-space-100: 0.5rem;
    --ic-size-space-150: 0.75rem;
    --ic-size-space-200: 1rem;
    --ic-size-space-250: 1.25rem;
    --ic-size-space-300: 1.5rem;
    --ic-size-space-400: 2rem;
    --ic-size-space-500: 2.5rem;
    --ic-size-space-600: 3rem;

    // border-radius

    --ic-size-radius-0: 0rem;
    --ic-size-radius-025: 0.125rem;
    --ic-size-radius-050: 0.25rem;
    --ic-size-radius-075: 0.375rem;
    --ic-size-radius-100: 0.5rem;
    --ic-size-radius-150: 0.75rem;
    --ic-size-radius-200: 1rem;
    --ic-size-radius-300: 1.5rem;

    // font size
    --ic-font-size-xs: 0.75rem;
	--ic-font-size-sm: 0.875rem;
	--ic-font-size-md: 1rem;
	--ic-font-size-lg: 1.25rem;
	--ic-font-size-xl: 1.5rem;
	--ic-font-size-xxl: 1.875rem;
	--ic-font-size-3xl: 2.25rem;

    --ic-icon-arrow-right: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='black' d='M4 11v2h12l-5.5 5.5 1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5 16 11H4Z'/%3e%3c/svg%3e");
    --ic-icon-check-circle: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='%230EA643' d='M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8-1.41-1.42Z'/%3e%3c/svg%3e");
    --ic-icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points=' 6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --ic-icon-circle: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='black' d='M12 20a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm0-18a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z'/%3e%3c/svg%3e");
    --ic-icon-minus: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='black' d='M19 13H5v-2h14v2Z'/%3e%3c/svg%3e");
    --ic-icon-plus: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cpath fill='black' d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2Z'/%3e%3c/svg%3e");

    --ic-color-surface: var( --bs-secondary-bg );
    --ic-color-surface-low: var( --ic-gray-95 );
    --ic-color-primary: var(--ic-color-red-60);
    --ic-color-primary-strong-rgb: var(--ic-color-red-30-rgb);
    --ic-color-primary-rgb: var(--ic-color-red-60-rgb);
    --ic-color-primary-low: var(--ic-color-red-95);
    --ic-color-success: var(--ic-color-green-50);
    
    --ic-color-onPrimary: var( --ic-white);
    
    --ic-color-text: var(--bx-color-text);
    --ic-color-link: var( --ic-color-primary );
    --ic-color-link-hover: var(--ic-color-red-50);

    --ic-color-border: var(--ic-gray-70);
    --ic-border-radius: var(--ic-size-radius-075);

    --ic-body-font-size: var( --ic-font-size-md );
    --ic-spacing: var(--ic-size-space-100);
    --ic-transition: .2s ease-in-out;

    --list-options-color-link: var(--ic-color-text);
    --list-options-color-link-active: var(--ic-color-link-hover);
    --list-options-color-link-hover : var(--ic-color-link-hover);

    --ic-dropzone-color-hover : var( --ic-gray-95);

    //bootstrap override
    --bs-primary-rgb: var(--ic-color-red-60-rgb);
}
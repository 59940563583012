.topmenu {
    .navbar-brand {
        display: block;
        max-width: 50%;
        @include breakpoint ( lg ) {
            max-width: fit-content;
        }
        img{
           max-width: 100%;
        }
    }
    .navbar-nav .nav-link {
        border-radius: var( --ic-border-radius );
        &.btn-ghost {
            color: var(--ic-color-primary);

            &:hover, &:focus {
                background-color: var(--ic-color-primary);
                color: var( --ic-color-onPrimary );
            }
        }
        &:hover, &:focus {
            background-color: var(--ic-color-primary);
            color: var( --ic-color-onPrimary );

            @include breakpoint ( lg ) {
                background-color: transparent;
                color: initial;
            }
            span {
                text-decoration: underline;
            }
        }
        i {
            font-size: var( --ic-font-size-lg );

            @include breakpoint ( lg ) {
                font-size: var( --ic-body-font-size );
            }
        }
    }
}
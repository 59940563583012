.card-blogentry {
    height: 100%;

    .card-media-wrapper {
        overflow: hidden;

        img {
            width: 100%;
            height: 250px;
            object-position: 50% 50%;
        }

        &.card-img-top img {
            object-fit: cover;
        }

        &.card-logo-top img{
            object-fit: scale-down;
        }
    }
    .card-tag {
        color: var(--ic-color-text);
        display: block;
        margin-bottom: var(--ic-spacing);
        text-decoration: none;
        text-transform: uppercase; 
    }
    .card-title {
        font-size: calc(1.275rem + .3vw);
        @include breakpoint(lg) {
            font-size: 1.5rem; 
        }
        a {
            text-decoration: none;
        }
        a:hover, a:focus {
            text-decoration: underline;
        }
    }
    .line-clamp {
        overflow: hidden;
        display: -webkit-box;
        line-clamp: 6;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;  
    }
}


.swatch-container {
    align-items: center;
    display: flex;
    gap: 0.25rem;
}
.swatch {
    --ic-swatch-size: 18px;

    border-radius: 50%;
    background-size: 120%;
    background-position: center;
    height: var(--ic-swatch-size);
    position: relative;
    width: var(--ic-swatch-size);
    
    &.form-check-input {
        align-items: center;
        border: 2px double var(--ic-color-border);
        cursor: pointer;
        display: flex;
        justify-content: center;
        
        &:checked[type=radio], &:checked[type=checkbox] {
            border: 0;
            border-color: rgba(var(--ic-color-primary-rgb), 0.5);
            box-shadow: 0 0 0 0.25rem rgba(var(--ic-color-primary-rgb), 0.5);

            &:after {
                font-family: "ic-icons" !important;
                content: "\e903";
                color: #fff;
                font-size: 0.6rem;
                margin: 0 auto;
                text-align: center;
            }
        }
    }
    &.swatch-ro {
        &:after {
            content: "";
            position:absolute;
            border: 1px solid var( --ic-color-surface );
            width: calc(var(--ic-swatch-size) - 4px );
            height: calc(var(--ic-swatch-size) - 4px );
            top: 2px;
            left: 2px;
            border-radius: 50%;
        }
    }   
}
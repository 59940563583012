.dropdown-menu {
    --bs-dropdown-box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
    --bs-dropdown-min-width: 13rem;
    box-shadow: var( --bs-dropdown-box-shadow );

    li a {
        border-bottom: 1px solid #ddd;
        color: var( --ic-color-primary );
        padding: 0.4rem 1.3rem;

        &:hover, &:focus {
            background-color: var( --ic-color-primary );
            color: #fff;
        }
    }
}
/* Starting Icons */

@font-face {
    font-family: "ic-icons";
    src: url(../../fonts/ic-icons.eot);
    src: url(../../fonts/ic-icons.eot#iefix) format("embedded-opentype"), url(../../fonts/ic-icons.ttf) format("truetype"), url(../../fonts/ic-icons.woff) format("woff"), url(../../fonts/ic-icons.svg#ic-icons) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^=icon-],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "ic-icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-water-bottle:before {
  content: "\e936";
}
.icon-up-arrow:before {
  content: "\e935";
}
.icon-zoom-in:before {
  content: "\e934";
}
.icon-image:before {
  content: "\e933";
}
.icon-expand:before {
  content: "\e932";
}
.icon-error:before {
  content: "\e907";
}
.icon-exclamation:before {
  content: "\e92f";
}
.icon-info:before {
  content: "\e930";
}
.icon-tag:before {
  content: "\e931";
}
.icon-read-more:before {
  content: "\e906";
}
.icon-hide:before {
  content: "\e904";
}
.icon-trousers:before {
  content: "\e905";
}
.icon-work-uniform:before {
  content: "\e92b";
}
.icon-bags:before {
  content: "\e900";
}
.icon-cap:before {
  content: "\e901";
}
.icon-cart:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e92a";
}
.icon-chevron-left:before {
  content: "\e919";
}
.icon-chevron-right:before {
  content: "\e918";
}
.icon-chevron-up:before {
  content: "\e92e";
}
.icon-close:before {
  content: "\e908";
}
.icon-contact:before {
  content: "\e909";
}
.icon-delete:before {
  content: "\e90a";
}
.icon-direct-print:before {
  content: "\e90b";
}
.icon-dress-shirt:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-embroidery:before {
  content: "\e90e";
}
.icon-ems:before {
  content: "\e90f";
}
.icon-enter:before {
  content: "\e910";
}
.icon-eye:before {
  content: "\e911";
}
.icon-facebook:before {
  content: "\e912";
}
.icon-filter:before {
  content: "\e913";
}
.icon-heat-transfer:before {
  content: "\e914";
}
.icon-hospitality:before {
  content: "\e915";
}
.icon-menu:before {
  content: "\e916";
}
.icon-message:before {
  content: "\e917";
}
.icon-minus:before {
  content: "\e91c";
}
.icon-outerwear:before {
  content: "\e91a";
}
.icon-patches:before {
  content: "\e91b";
}
.icon-phone:before {
  content: "\e91d";
}
.icon-plus:before {
  content: "\e91e";
}
.icon-poloshirt:before {
  content: "\e91f";
}
.icon-question:before {
  content: "\e920";
}
.icon-reflective-transfer:before {
  content: "\e921";
}
.icon-restaurant:before {
  content: "\e922";
}
.icon-screen-printing:before {
  content: "\e92c";
}
.icon-scrubs:before {
  content: "\e92d";
}
.icon-search:before {
  content: "\e923";
}
.icon-sportswear:before {
  content: "\e924";
}
.icon-tshirt:before {
  content: "\e925";
}
.icon-twitter:before {
  content: "\e926";
}
.icon-upload:before {
  content: "\e927";
}
.icon-user-plus:before {
  content: "\e928";
}
.icon-user:before {
  content: "\e929";
}
.icon-adidas:before {
  content: "\e937";
}
.icon-antigua:before {
  content: "\e938";
}
.icon-ogio:before {
  content: "\e939";
}
.icon-brooks:before {
  content: "\e93a";
}
.icon-callaway:before {
  content: "\e93b";
}
.icon-columbia:before {
  content: "\e93c";
}
.icon-cutter-and-buck:before {
  content: "\e93d";
}
.icon-greg-norman:before {
  content: "\e93e";
}
.icon-nike:before {
  content: "\e93f";
}
.icon-north-face:before {
  content: "\e940";
}
.icon-reebok:before {
  content: "\e941";
}
.icon-travismathew:before {
  content: "\e942";
}
.icon-under-armour:before {
  content: "\e943";
}
.icon-bolt:before {
  content: "\e944";
}
.icon-eddie-bauer:before {
  content: "\e945";
}
.icon-puma:before {
  content: "\e946";
}
.icon-richardson:before {
  content: "\e947";
}
.icon-polo-shirt-dollar:before {
  content: "\e948";
}
.icon-free-shipping:before {
  content: "\e949";
}
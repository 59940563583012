.card-product-container {
    .card-image-container {
        @include breakpoint(lg) {
            min-height: 250px;
        }
    }
    .card-img-top {
        max-height: 225px;
        width: auto;
    }

    .card-title,
    .card-text {
        max-width: 100%;
    }
    .card-title {
        button {
            font-weight: bold;
            &[data-ic-cardlink] {
                padding-left: 0;
            }
            
        }
    }
    .clickable {
        &:hover {
            cursor: pointer;
        }
    }
}

.card-product-container .hover-card, .card-artwork-container .hover-card {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
}

.card-product-container .card:hover .hover-card, 
.card-artwork-container .card:hover .hover-card {
    background-color: var( --ic-color-primary );
    opacity: 0.8;
}

.card-product-container .card:hover .hover-card .btn-outline-light:hover,
.card-artwork-container .card:hover .hover-card .btn-outline-light:hover {
    background-color: #fff;
    color: var(--ic-color-primary);
    border-color: #fff;
}


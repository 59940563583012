.preview-img-wrapper {
    width: 90vw;

    @include breakpoint( sm ) {
        width: 50vw;
        height: 35vh;
    }
}
.preview-img {
    max-width: 100%;
    max-height: 100%
}
.dropzone {
    border: 2px dashed var( --ic-color-border );
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &:hover {
        background-color: var(--ic-dropzone-color-hover);
    }
}
.dz-preview .dz-error-message {
    top: 150px!important;
}
.icpd .dropzone {
    height: 40vh;

    .dz-message .icon-upload {
        font-size: 5em;
    }
}
.expanding-search-bar {
    --size: 46px;
    --padding: 0px;
  
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    border-radius: 6px;
    outline: 1px solid var(--ic-color-primary);
    overflow: hidden;
    padding: var(--padding);
    width: var(--size);
    height: 100%;
    transition:  width 0.5s;
    
    .search-input {
        background-color: transparent;
        border: none;
        outline: none;
        flex: auto;
        width: 0px;
        opacity: 0;
        transition: opacity 0.5s;
    }
  
    &.open {
        width: 100%;
        
        .search-input {
            opacity: 1;
            padding-left: var( --ic-spacing );
            padding-right: var( --ic-spacing );
            width: inherit;
        }
    }

    .search-submit {
        flex: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: calc(var(--size));
      }
}

.captcha-placeholder {
    background: #f9f9f9;
    border: 1px solid #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
    width: 300px;
}
  
  
  
 
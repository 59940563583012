.nav-tabs.list-options {
    --bs-nav-tabs-link-active-bg : transparent;
    
    border-bottom-color: transparent;
    li {
        padding-left:1.7em;
        position: relative;

        button {
            border-bottom: 2px solid transparent;
            color: var(--list-options-color-link);
            padding-left: 0px;
            text-align: left;
            width: 100%;
            
            &:hover, &:focus {
                border-bottom-color: var(--list-options-color-link-hover);
                border-right-color: transparent;
                border-left-color: transparent;
                border-top-color: transparent;
                color: var(--list-options-color-link-hover);
            }
        }
        &:before {
            background-color: var(--bs-body-bg);
            content: '';
            position: absolute;
            left: 0;
            top: .5em;
            width: 1.4em;
            height: 1.4em;
            background-repeat: no-repeat;
            -webkit-mask: var(--ic-icon-circle);
            mask: var(--ic-icon-circle);
            -webkit-mask-size: cover;
            mask-size: cover;
        }
        &.active {
            button {
                border-bottom-color: var(--list-options-color-link-hover);
                border-right-color: transparent;
                border-left-color: transparent;
                border-top-color: transparent;
                color: var(--list-options-color-link-hover);
            }
        }
        &.selected {
            &:before {
                background-color: var(--ic-color-success);
                -webkit-mask: var(--ic-icon-check-circle);
                mask: var(--ic-icon-check-circle);
            }
        }
    }
}

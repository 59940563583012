.vtabs {
    border-bottom: 1px solid var( --ic-color-border );
    grid-area: vtabs;
}
.vtabsheader {
    background-color: var(--ic-color-surface-low);
    grid-area: vtabsheader;
}
.vtabsbody {
    grid-area: vtabsbody;
    padding: 1rem;
}
.product-layout {
    display: grid;
    gap: 0 1.5rem;
    grid-template-areas: 
            "productheader"
            "productimage"
            "productbody";
}
.product-header {
    grid-area: productheader;
}
.product-body {
    grid-area: productbody;
}
.product-image {
    grid-area: productimage;
}
@media ( min-width: 992px ) {
    .vtabs {
        border-right: 1px solid var( --ic-color-border );
        border-bottom: none;
    }
    .vtabsbody {
        padding-left: 0;
    }
    .vtabs-layout {
        display: grid;
        gap: 0 1.5rem;
        grid-template-areas: 
            "vtabsheader vtabsheader"
            "vtabs vtabsbody";
        grid-template-columns: 1fr 5fr;
        grid-template-rows: min-content auto;
        height: 100%;
    }
    .product-layout {
        grid-template-areas: 
            "productimage productheader"
            "productimage productbody";
        grid-template-columns: 2fr 5fr;
    }
}
/*!***********************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!./node_modules/resolve-url-loader/index.js??ruleSet[1].rules[4].use[2]!./resources/assets/css/app.css ***!
  \***********************************************************************************************************************************************************************************/
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
/*!***************************************************************************************************************************************************************************************!*\
  !*** css ./node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!./node_modules/resolve-url-loader/index.js??ruleSet[1].rules[4].use[2]!./resources/assets/css/app.css (1) ***!
  \***************************************************************************************************************************************************************************************/
@import "~dropzone/src/dropzone";

@import './partials/variables';
@import './partials/mixins';
@import './partials/icons';
@import './partials/typography';
@import './partials/layout';
@import './partials/form';

@import './partials/dropdown';
@import './partials/buttons';
@import './partials/swatch';
@import './partials/accordion';
@import './partials/nav';
@import './partials/modal';

@import './partials/breadcrumb';
@import './partials/topmenu';
@import './partials/navmain';
@import './partials/productdecoration';
@import './partials/productfilters';
@import './partials/cardproduct';
@import './partials/cardblogentry';
@import './partials/cartitem';
@import './partials/slidesmanager';

.bg-light-grey {
    background-color: #b3b3b3;
}

.bg-lighter-grey {
    background-color: rgba(179, 179, 179, 0.2);
}

body {
    font-family: "Lato", sans-serif !important;
    font-size: 1rem;
    color: #333;
    padding: 0;
    margin: 0;
    position: relative;
    min-height: 100vh;
}

.min-h-100 {
    min-height: 100vh;
}
.mh-inherit {
    min-height: inherit;
}

.offcanvas {
    background-color: rgba(212, 48, 54, 0.8);
}

.fs-l {
    font-size: 3rem;
}

.fs-s {
    font-size: 0.9rem;
}

.fs-xs {
    font-size: 0.8rem;
}

.text-body {
    color: #333;
}

.text-red {
    color: #D43036;
}

.text-second {
    color: #02A5D5;
}

.text-grey {
    color: #808080;
}

.text-light-grey {
    color: #b3b3b3;
}

.content-editable {
    position: relative; /* Ensures the icon is positioned relative to this container */
}

.edit-icon {
    position: absolute;
    top: 0px; /* Position the icon above the content */
    right: 10px;
    opacity: 1;
    cursor: pointer;
}

.custom-tooltip {
    --bs-tooltip-bg: var(--bs-primary);
}

footer {
    background-color: #333;
    padding-left: 5%;
    color: #fff;
    margin-top: 0px;
    margin-left: 256px;
}

footer a {
    padding: 0.1rem;
    border-radius: 12px;
    color: #fff;
    text-decoration: none;
}

footer a:hover {
    color: #fff;
    text-decoration: underline;
}

footer .white-link:hover {
    background-color: #fff;
    color: var(--ic-color-primary);
}

/* Layouts  */
.short .main,
.short .footer {
    margin-left: 70px;
    transition: margin-left 0.1s ease-in-out;
}

@media (max-width: 844px) {
    .container .sidebar {
        width: 100%;
        margin-left: 0;
        text-align: center;
    }

    .container .sidebar .logo,
    .container .sidebar .searchInput,
    .container .sidebar .text,
    .container .sidebar .avatar,
    .container .sidebar .name {
        display: none;
    }

    .container .sidebar .sidebartop {
        display: block;
        height: auto;
    }

    .container .sidebar .sidebartop .logo-mobile {
        display: block;
    }

    .container .sidebar .sidebartop .logo-mobile img {
        height: auto;
        width: 80%;
    }

    .container .sidebar nav ul li {
        padding: 0;
    }

    .container .sidebar nav ul li a {
        padding: 0.6rem 0;
    }

    .container .sidebar .account {
        display: block;
    }

    .container .sidebar .account .logout {
        width: 100%;
        text-align: center;
    }

    .container .sidebar .search {
        margin: 1.5rem -0.2rem;
    }
}

.searchWindow {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(51, 51, 51, 0.9);
    z-index: 1;
    top: 0;
    padding: 1rem;
    text-align: center;
    color: #fff;
    padding-top: 20vh;
    display: none;
}

.searchWindow input {
    background-color: #bfbfbf;
    border: 0px;
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    width: 60vw;
}

.searchWindow input::placeholder {
    padding-left: 0.5rem;
}

.searchWindow button {
    background-color: transparent;
    border: 2px solid #fff;
    font-size: 1rem;
    padding: 1rem 2rem;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
}

.searchWindow button.cancelSearch {
    border: 0px;
    font-size: 2rem;
    position: absolute;
    top: 0;
    right: 2vw;
}

.searchWindow.active {
    display: block;
}

.auto-close {
    white-space: inherit;
    text-align: left;
}

.auto-close i {
    font-size: 80%;
    padding-left: 7px;
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--ic-color-primary);
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--bs-dropdown-link-active-color);
    background-color: #02A5D5;
}

.pagination .page-link {
    width: 40px;
    height: 40px;
    color: var(--ic-color-primary);
    font-size: 1.2rem;
    border-radius: 10px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    border-color: var(--ic-color-primary);
}

.pagination .page-link:hover, .pagination .page-link.active {
    background-color: var(--ic-color-primary);
    color: #fff;
}

.list-checked {
    list-style: url(../images/icon-check.svg);
}

.list-checked li {
    padding-bottom: 0.6rem;
}

.carousel-products .carousel-caption {
    right: 0;
    left: 0;
    bottom: 0;
    padding-bottom: 0;
}

.carousel-products .carousel-indicators {
    bottom: 65px;
}

.carousel-products .carousel-indicators {
    background-color: #000;
    position: inherit;
    margin-right: 0;
    margin-left: 0;
}

.carousel-products .carousel-indicators a {
    width: 75px;
    height: 94px;
}

.qty-input-cont {
    display: flex;
    gap: 0.3rem;
}

.qty-input-cont input {
    text-align: center;
    width: 4rem;
}

.order-table th,
.order-table td {
    vertical-align: middle;
    text-align: center;
}

.customization-buttons-container .btn,
.customization-buttons-container .customization-button {
    border: 1px solid rgba(179, 179, 179, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0.5rem;
}

.customization-buttons-container .btn i,
.customization-buttons-container .customization-button i {
    height: 60px;
    width: 60px;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customization-buttons-container i {
    color: var(--ic-color-primary);
}

.drag-area {
    width: 100%;
    height: 250px;
    border-width: 3px !important;
    border-style: dashed !important;
}

.drag-area label {
    width: 100%;
    height: 100%;
}

.drag-area .icon {
    font-size: 5rem;
    color: #b3b3b3;
}

.drag-area .filename {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: rgba(179, 179, 179, 0.2);
    width: 80%;
}

.drag-area .file-info {
    width: 80%;
}

.modal-logos-on-file .modal-body {
    height: 50vh;
}

.modal-logos-on-file .modal-body .all-logos {
    overflow-y: scroll;
}
.cart-items {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill,100%);
}


.cart-item-name {
    a {
        text-decoration: none;
        border-bottom: 1px dotted var(--main-color);
    }
}
.cart-item .item-img img {
    width: 65%;
}
.cart-checkout-summary-table {
    --bs-table-bg: transparent;

    margin-bottom: 0px;

    th {
        padding-left: 0px;
        padding-bottom: .3rem;
        padding-top: .3rem;
    }
    td {
        padding-right: 0px;
        padding-bottom: .3rem;
        padding-top: .3rem;

    }
    tbody tr:last-child th,  tbody tr:last-child td {
        padding-bottom: .5rem;
    }
    tfoot tr:first-child th, tfoot tr:first-child td   {
        border-top: 1px solid #707070;
    }
}
.cart-checkout-accordion {
    --bs-accordion-btn-bg: rgba(179, 179, 179, 0.2);
}


.form-check {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.form-check-input {
    width: 1.3rem;
    height: 1.3rem;
    margin-top: 0.06rem;
    margin-right: 0.6rem;
}
.color-options .form-check .form-check-input {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: -1.75em;
}

.form-check-input:checked {
    background-color: var(--ic-color-primary);
    border-color: var(--ic-color-primary);
}

.form-check-input:focus {
    border-color: rgba(212, 48, 54, 0.5);
    box-shadow: 0 0 0 0.25rem rgba(212, 48, 54, 0.5);
}

.form-range {
    width: 100%;
    height: 1.5rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 16px;
    height: 16px;
    -webkit-appearance: auto;
    appearance: auto;
}

.checkbox-gap {
    display: inline-flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.checkbox-gap .btn {
    width: 2.7rem;
    height: 2.7rem;
    flex: inherit;
    border-radius: 4px !important;
}

.offcanvas {
    background-color: rgba(212, 48, 54, 0.8);
}

.offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: 200px;
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
}

.cart-preview {
    font-size: 14px;
}

.cart-preview .offcanvas-body {
    display: flex;
}

.cart-preview .items-on-cart {
    background-color: #fff;
    overflow-y: scroll;
    padding: 25px;
}

.cart-preview .items-on-cart .item-details { 
    background-color: rgba( var( --bs-secondary-bg-rgb), .85);
    z-index: 10;
    width: 100%;
    height: 100%;
    border-radius: var(--bs-border-radius);
}

.cart-preview .items-on-cart .item-preview:hover {
    cursor: pointer;
}

.cart-preview .items-on-cart .item-preview:hover .cta-cartpreview-show-details {
    background-color: var(--ic-color-primary);
    color: #fff;
}

.cart-preview .items-on-cart .list-details li {
    margin-bottom: 0.5rem;
}

.cart-preview .items-on-cart .list-details li .label {
    display: block;
    width: 40%;
}

.cart-preview .items-on-cart .list-details li .data {
    width: 60%;
    text-align: center;
}

.cart-preview .items-on-cart .list-details li .data div {
    margin: 0 auto;
}

.cart-preview .list-details-actions button {
    background-color: #fff;
    border: 1px solid var(--ic-color-primary);
    color: var(--ic-color-primary);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 16px;
}

.cart-preview .list-details-actions button:hover, .cart-preview .list-details-actions button:focus {
    background-color: var(--ic-color-primary);
    color: #fff;
}

.cart-preview .items-on-cart .cta-cartpreview-show-details {
    font-size: 1.2rem;
    transition: background-color 0.15s ease-in-out;
}

.cart-preview .items-on-cart .active .cta-cartpreview-show-details {
    background-color: #000000 !important;
    color: #fff;
}

.my-account-card {
    min-height: 300px;
}

@media (max-width: 991px) {
    body {
        font-size: 1rem;
    }

    .short .main,
    .short .footer {
        margin-left: 0px;
    }

    .card-image-container .card {
        display: flex;
        flex-direction: row;
    }

    .card-image-container .card .card-body {
        width: 60%;
    }

    .card-product-container .card {
        display: flex;
        flex-direction: row;
    }

    .card-product-container .card:hover {
        background-color: rgba(179, 179, 179, 0.2);
        border-color: rgba(179, 179, 179, 0.2);
    }

    .card-product-container .card:hover .hover-card {
        display: none !important;
    }

    .card-product-container .card .card-img-top,
    .card-product-container .card img {
        max-width: 60px;
        height: auto;
    }

    .card-product-container .card .card-image-container {
        display: flex;
        align-items: center;
    }

    .card-product-container .card .card-body {
        width: 60%;
    }

    .custom-container .main {
        margin-left: 0px;
        transition: margin-left 0.1s ease-in-out;
    }

    footer {
        padding: 40px 0;
        height: auto;
        margin-left: 0px;
    }

    footer .accordion-item {
        border-radius: 12px !important;
        border: 2px solid #fff !important;
    }

    footer .accordion-item .accordion-button {
        color: #fff;
    }

    footer .accordion-item .accordion-button i {
        transition: rotate 0.15s ease-in-out;
    }

    footer .accordion-item .accordion-button:not(.collapsed) i {
        rotate: 180deg;
        transition: rotate 0.15s ease-in-out;
    }

    footer .accordion-item .accordion-button:focus {
        z-index: 3;
        border-color: #fff;
        outline: 0;
        box-shadow: #fff;
    }

    footer .accordion-item .dropdown-item {
        padding: 0.6rem 1rem;
    }

    footer .accordion-item .dropdown-item:hover {
        background-color: #fff;
        color: #333;
    }

    .swatch-container .swatch {
        width: 30px;
        height: 30px;
    }

    .product-filters-container .accordion-button {
        color: #333 !important;
    }

    .custom-container .footer {
        margin-left: 0px;
    }
}

@media (max-width: 575px) {
    .customization-buttons-container .btn {
        padding: 0.5rem;
    }

    .customization-buttons-container .btn span {
        display: none;
    }

    .cart-item .item-img {
        margin-bottom: 1rem;
    }

    .cart-item .item-img img {
        width: auto;
        max-height: 13rem;
    }

    .cart-item .item-order-details {
        flex-wrap: wrap;
    }
}

.processing {
    opacity: 0.5;
}

.cursor-pointer {
    cursor: pointer;
}
.step-num {
    margin-right: var(--ic-spacing);
    border-radius: 100%;
    border: 1px solid var(--ic-color-primary);
    line-height: 1.5em;
    width: 1.75em;
    width: 1.7em;
    text-align: center;
    display: inline-block;
    color: var(--ic-color-primary);
}
/*# sourceMappingURL=app.css.map */
details summary:after {
    display: block;
    width: 1rem;
    height: 1rem;
    float: right;
    transform: rotate(-90deg);
    -webkit-mask: var(--ic-icon-chevron) no-repeat 50% 50%; 
    mask: var(--ic-icon-chevron) no-repeat 50% 50%; 
    -webkit-mask-size: cover;
    mask-size: cover;
    background-position: right center;
    background-size: 1rem auto;
    background-repeat: no-repeat;
    content: "";
    transition: transform var(--ic-transition);
    background:currentColor;
}
details summary::marker{
    display:none;
    content:"";
}

details[open]>summary:after {
    transform: rotate(0);
}

.accordion {
    --bs-accordion-btn-bg : transparent;
    --bs-accordion-active-bg: var(--ic-color-surface-low);
}

.accordion-button {
    font-weight: bold;
}

.accordion-button::after {
    -webkit-mask: var(--ic-icon-plus) no-repeat 50% 50%; 
    mask: var(--ic-icon-plus) no-repeat 50% 50%; 
    -webkit-mask-size: cover;
    mask-size: cover;
    background:currentColor;
}

.accordion-button:not(.collapsed)::after {
    -webkit-mask: var(--ic-icon-minus) no-repeat 50% 50%; 
    mask: var(--ic-icon-minus) no-repeat 50% 50%; 
}
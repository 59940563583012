h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}

h1, h2 {
    color: var(--ic-color-primary);
}
a {
    color: var(--ic-color-link);
    transition: color 0.1s ease-in-out;
}

a:hover, a:focus {
    color: var(--ic-color-link-hover);
}

.text-emphasis-high {
    color: var(--ic-color-red-60);
    font-size: var(--ic-font-size-lg);
    font-weight: bold;
}
ol.list-circle-num {
    padding: 0;
    list-style: none;
    counter-reset: item;

    &.inline {
        display: flex;
        flex-wrap: wrap;
        
        gap: var(--ic-spacing);
        &.arrow {
            li {
                text-wrap: nowrap;
            }
            li:not(:last-child):after {
                content: "";
                -webkit-mask: var(--ic-icon-arrow-right) no-repeat 50% 50%; 
                mask: var(--ic-icon-arrow-right) no-repeat 50% 50%; 
                -webkit-mask-size: cover;
                mask-size: cover;
                width: 1em;
                height: 1em;
                display: inline-block;
                background:currentColor;
                vertical-align: middle;
            }
        }
    }
    li {
        counter-increment: item;
        margin-bottom: 5px;
      }
      li:before {
        margin-right: var(--ic-spacing);
        content: counter(item);
        border-radius: 100%;
        border: 1px solid var(--ic-color-primary);
        width: 1.75em;
        width: 1.70em;
        text-align: center;
        display: inline-block;
        color: var(--ic-color-primary);
      }
}

.modal {
    --bs-modal-zindex: 1094;
}
.modal-alert-backdrop{
    --bs-backdrop-zindex: 1094;
}
.modal-alert {
    --bs-modal-zindex: 1096;

    .btn-close {
        position: absolute;
        top: calc( var(--ic-spacing ) * 2 );
        right: calc( var(--ic-spacing ) * 2 );
    }
    .modal-header {
        border-bottom: none;
        flex-direction: column;
        justify-content: center;
        padding-top: calc( var(--ic-spacing ) * 4 )
    }
    .modal-footer {
        border-top: none;
    }
}
.modal-search-backdrop{
    --bs-backdrop-zindex: 1093;
}
.modal-fw {
    --bs-modal-width : 100%;
    --bs-modal-margin: 0;
    --bs-modal-border-radius: 0;

    .btn-close {
        position: absolute;
        top: calc( var(--ic-spacing ) * 2 );
        right: calc( var(--ic-spacing ) * 2 );
    }
}
.product-filters-container {
    .accordion-item {
        border-radius: var( --bs-accordion-border-radius);
    }
}

.product-filters-container .auto-scroll {
    padding: 0.5rem 0 0.5rem 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    height: 200px;
}

.applied-filters {
    background-color: rgba(179, 179, 179, 0.2);
}

.applied-filters-badges-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 90px;
}

.applied-filters-badges-container .badge {
    background-color: rgba(2, 165, 213, 0.1);
    color: #02A5D5;
    transition: background-color 0.15s ease-in-out;
}

.applied-filters-badges-container .badge:hover {
    background-color: #02A5D5;
    color: #fff;
}
.cart-item-img {
    min-width: 100px;

    img {
        max-width: 100%;
    }
}
.cart-item-content {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: min-content 1fr ;
    grid-template-areas: 
    "head"
    "total"
    "qty"
    "attr"
    "actions";

    @include breakpoint( xl ) {
        grid-template-rows: auto 1fr;
        grid-template-columns: 2fr 1fr 1fr 1fr min-content;
        grid-template-areas: 
        "head head head head"
        "attr qty price total"
        "actions actions actions actions";
    }
}
.cart-item-content > .cart-item-head {
    grid-area: head;
}
.cart-item-content > .cart-item-attr {
    grid-area: attr;
}
.cart-item-content > .cart-item-qty {
    grid-area: qty;
    @include breakpoint( xl ) {
        display: flex;
        flex-direction: column;
    }
}
.cart-item-content >.cart-item-price {
    grid-area: price;
    display: none;
    @include breakpoint( xl ) {
        display: flex;
        flex-direction: column;
        padding-top: .5rem;
        text-align: right;
    }
}
.cart-item-content >.cart-item-total {
    font-size: 1.25rem;
    grid-area: total;
    @include breakpoint( xl ) {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        padding-top: .5rem;
        text-align: right;
    }
}
.cart-item-content >.cart-item-actions {
    grid-area: actions;
}
.cart-item-decorations .head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint( xl ) {
        flex-direction: row;
    }
}
.cart-artwork-card {
    container-type: inline-size;
    container-name: artworkCard;

    .card-body {
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
    }
}

@container artworkCard (min-width: 500px) {
    .cart-artwork-card {
        .card-body {
            flex-direction: row;
        }
    }
}
.cart-preview-artwork {
    max-height: 120px;
    max-width: 120px;
}
.short {
    .cart-item-content {
        @include breakpoint( lg ) {
            grid-template-rows: auto 1fr;
            grid-template-columns: 2fr 1fr 1fr 1fr min-content;
            grid-template-areas: 
            "head head head head head"
            "attr qty price total actions";
        }
    }
    .cart-item-content >.cart-item-price {
        @include breakpoint( lg ) {
            display: block;
            padding-top: .5rem;
            text-align: right;
        }
    }
    .cart-item-content >.cart-item-total {
        @include breakpoint( lg ) {
            font-size: 1rem;
            padding-top: .5rem;
            text-align: right;
        }
    }
}